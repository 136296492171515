<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑考勤' : '添加考勤'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="100px"
      label-position="right"
      :model="form.data"
      :rules="form.rules"
      :size="subUnitSize"
    >
      <el-form-item v-if="isChange" label="用工单位">
        <el-input v-model="form.data.name" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item v-else prop="company_id" label="用工单位">
        <el-select
          v-model="form.data.company_id"
          placeholder="请选择所属单位"
          clearable
          filterable
          :disabled="isChange"
          style="width:100%;"
        >
          <el-option
            v-for="item in companyOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="working_day_set" label="打卡日期">
        <el-select v-model="form.data.working_day_set" multiple style="width:100%;">
          <el-option label="周一" value="1"></el-option>
          <el-option label="周二" value="2"></el-option>
          <el-option label="周三" value="3"></el-option>
          <el-option label="周四" value="4"></el-option>
          <el-option label="周五" value="5"></el-option>
          <el-option label="周六" value="6"></el-option>
          <el-option label="周日" value="7"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="end_time" label="上班时间">
        <el-row>
          <el-col :span="12">
            <el-time-select
              placeholder="起始时间"
              v-model="form.data.start_time"
              :picker-options="{
                start: '06:00',
                step: '00:15',
                end: '20:00'
              }"
              style="width:160px">
            </el-time-select>
          </el-col>
          <el-col :span="12">
            <el-time-select
              placeholder="结束时间"
              v-model="form.data.end_time"
              :picker-options="{
                start: '06:00',
                step: '00:15',
                end: '20:00',
                minTime: form.data.start_time
              }"
              style="width:160px">
            </el-time-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="name" label="打卡方式">
        <span>定位+拍照</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'SetEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            company_id: '',
            working_day_set: [],
            start_time: '',
            end_time: '',
          },
          rules: {
            company_id: [{required: true, message: "必填项", trigger: "change"}],
            working_day_set: [{required: true, message: "必填项", trigger: "change"}],
            start_time: [{required: true, message: "必填项", trigger: "change"}],
            end_time: [{required: true, message: "必填项", trigger: "change"}],
          }
        },
        companyOpt: [],
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.getCompany();
        if (!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.working_day_set = row.working_day_set.split(",")
            this.form.data.company_id = row.id; // 用工单位
          })
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.form.data.company_id = '';
        this.form.data.start_time = '';
        this.form.data.end_time = '';
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      getCompany() {
        //is_open_shift(1-开启；0-未开启)获取未开启打卡企业列表
        this.$http.get('/admin/company/searchList',{params:{is_open_shift:0}}).then(res => {
          if(res.code) {
            this.companyOpt = res.data;
          } else {
            this.$message.eroor(res.msg)
          }
        })
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = !this.isChange ? '/admin/company/createAttendance' : '/admin/Company/editAttendance'
            let _params = {...this.form.data}
            _params.working_day_set = this.form.data.working_day_set.join(",")
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
             this.form.loading = false;
            })
          }
        })
      },
     
    }
  }
</script>

