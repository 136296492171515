<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="开票单位" prop="invoice_company_id" v-if="icId == 0">
          <el-select v-model="table.params.invoice_company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in invoiceCompanyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <!-- 总管理端没有添加考勤功能  -->
        <el-button v-if="icId != 0" :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">添加考勤</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="icId == 0 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:start_time="{row}">
        <span>{{row.start_time}}-{{row.end_time}}</span>
      </template>
      <template v-slot:shift_status="{row}">
        <el-switch
          v-model="row.shift_status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click='showEdit(row)'>编辑</el-button>
      </template>
    </VTable>
    
    <edit ref="edit" @refresh="getTable" ></edit>
    
  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import VTable from '@/components/VTable';
import {getStorage} from '@/storage'
import { mapGetters } from 'vuex'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    Edit,
    VTable
  },
  computed: {
    ...mapGetters({
      invoiceCompanyOpt: 'invoiceCompany'
    })
  },
  data() {
    return {
      icId: Number(getStorage('icId')), 
      token: getStorage('token'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "用工单位", hidden: false },
        { name: "invoice_company_name", label: "开票单位", hidden: false },
        { name: "start_time", label: "上班时间", hidden: false},
        { name: "shift_status", label: "状态", width:'120', hidden: false},
        { name: "open_shift_time", label: "创建时间", width:"160", hidden: false},
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "用工单位", hidden: false },
        { name: "start_time", label: "上班时间", hidden: false},
        { name: "shift_status", label: "状态", width:'120', hidden: false},
        { name: "open_shift_time", label: "创建时间", width:"160", hidden: false},
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          invoice_company_id: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/company/attendanceList', {params:this.table.params}).then(res => {
        if(res.code == 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 状态
    handleStatus(row) {
      this.$http.post('/admin/Company/updateAttendance',{company_id:row.id,status:row.shift_status}).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          this.getTable();
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
  }
}
</script>